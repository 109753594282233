import React, { Fragment, useEffect, useState } from 'react';
import {
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Row,
  Spinner
} from 'react-bootstrap';
import SoftBadge from '../../../../common/SoftBadge';
import IconAlert from '../../../../common/IconAlert';
import {
  ExportSheet,
  IndexContract,
  showContract
} from '../../../../../services/Contract/ContractService';
import { toast } from 'react-toastify';
import { IndexPartnerWithTrashed } from '../../../../../services/PartnerService';
import AddContract from './addContract';
import ManageContracts3 from './manage3';
import AddDereferencement from './AddDereferencement ';
import EmailLogsContractsTable from './indexMails';
import { ExportPoints } from '../../../../../services/Point/PointService';
import moment from 'moment/moment';

const IndexContracts3 = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [trigger, setTrigger] = useState(Date.now());
  const [partners, setPartners] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [exportUrl, setExportUrl] = useState('');
  const [exportIsLoading, setExportIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchContracts = async () => {
      const responseObjects = await IndexContract();
      if (responseObjects.success === true) {
        setContracts(responseObjects.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    const fetchPartners = async () => {
      const responseObject = await IndexPartnerWithTrashed();
      if (responseObject.success === true) {
        let selectObjects = [];
        responseObject.data.map(partner => {
          selectObjects.push({
            value: partner.id,
            label: partner.name
          });
        });
        setPartners(selectObjects);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchContracts();
    fetchPartners();
  }, [trigger]);

  const computeEtatContrat = contract => {
    const now = new Date();
    if (contract.dereferencement_date) {
      const date = new Date(contract.dereferencement_date);
      return `Déréférencé le ${date.toLocaleDateString('fr-FR')}`;
    } else if (
      contract.date_debut_contrat &&
      new Date(contract.date_debut_contrat) < now &&
      (!contract.date_fin_contrat || new Date(contract.date_fin_contrat) > now)
    ) {
      //return 'En cours';
      return '';
    } else if (
      contract.date_fin_contrat &&
      new Date(contract.date_fin_contrat) <= now
    ) {
      return 'Expiré';
    } else {
      return '';
    }
  };

  const computeInvoiceToProcess = contract => {
    let count = 0;
    [2023, 2024, 2025, 2026, 2027, 2028].map(year => {
      if (contract[`etat_facture_caa_rfa_${year}`] == 2) {
        count++;
      }
      if (contract[`etat_facture_salon_${year}`] == 2) {
        count++;
      }
    });
    return count;
  };

  const computeInvoiceToPay = contract => {
    let count = 0;
    [2023, 2024, 2025, 2026, 2027, 2028].map(year => {
      if (
        contract[`etat_facture_caa_rfa_${year}`] == 3 &&
        !contract[`date_paiement_caa_rfa_${year}`]
      ) {
        count++;
      }
      if (
        contract[`etat_facture_salon_${year}`] == 3 &&
        !contract[`date_paiement_salon_${year}`]
      ) {
        count++;
      }
    });
    return count;
  };

  const exportSheet = async year => {
    setExportIsLoading(true);
    const responseExport = await ExportSheet(year);
    if (responseExport.success === true) {
      setExportIsLoading(false);
      setExportUrl(responseExport.data);
    } else {
      setExportIsLoading(false);
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      {isLoading === true && (
        <Row>
          <Col xs={12} className="mt-3">
            <Spinner animation="border" role="status" size="xs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
      <Row className="justify-content-between align-items-center mt-3">
        <Col lg={6}>
          <h3 className="text-primary admin-title mb-0">
            Contrats partenaires
          </h3>
        </Col>
        <Col lg={6} className="text-end">
          <DropdownButton
            as={ButtonGroup}
            variant="falcon-default"
            size="sm"
            title={
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                    fill="rgba(75,74,77,1)"
                  />
                </svg>{' '}
                Exports
              </>
            }
            className="me-2"
          >
            <Dropdown.Item
              eventKey="2"
              onClick={() => {
                setExportUrl('');
                exportSheet('all');
              }}
              disabled={exportIsLoading === true}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                style={{ marginBottom: '2px', marginRight: '2px' }}
              >
                <path
                  d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                  fill="rgba(75,74,77,1)"
                />
              </svg>
              Export TOUT
            </Dropdown.Item>
            {[2023, 2024, 2025, 2026, 2027, 2028].map(year => (
              <Dropdown.Item
                key={year}
                eventKey="2"
                onClick={() => {
                  setExportUrl('');
                  exportSheet(year);
                }}
                disabled={exportIsLoading === true}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  style={{ marginBottom: '2px', marginRight: '2px' }}
                >
                  <path
                    d="M6 7V4a1 1 0 0 1 1-1h6.414l2 2H21a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3zm0 2H4v10h12v-2H6V9zm2-4v10h12V7h-5.414l-2-2H8z"
                    fill="rgba(75,74,77,1)"
                  />
                </svg>
                Export {year}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
        {exportIsLoading === true && (
          <Col xs={12} className="mt-2">
            <Spinner animation="border" role="status" size="xxs">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        )}
        {exportUrl && (
          <Col xs={12} className="mt-2">
            <IconAlert
              variant="success"
              dismissible
              onClose={() => setExportUrl('')}
              className="mt-3"
            >
              <p className="mb-0">
                Export Excel des contrats disponible :{' '}
                <a href={exportUrl}>cliquez ici pour le télécharger</a>
              </p>
            </IconAlert>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={4}>
          <Card className="mt-3">
            <Card.Body className="">
              <Row>
                <Col xs={12} className="mt-3">
                  <AddContract
                    partners={partners}
                    setParentTrigger={setTrigger}
                  />
                  <AddDereferencement
                    setParentTrigger={setTrigger}
                    partners={partners}
                    initialContracts={contracts}
                  />
                </Col>
                <Col xs={12} className="mt-3">
                  <h5 className="admin-title companyRightManagePeople">
                    Choisir un partenaire :
                  </h5>
                </Col>
                <Col xs={12} className="mt-3 mb-2">
                  <Row>
                    {contracts.map((object, index) => (
                      <Col
                        key={index}
                        className="cursor-pointer smaller-p mb-1"
                        xs={12}
                        onClick={() => {
                          setSelectedObject(object);
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                        style={{
                          backgroundColor:
                            selectedObject?.id === object.id ? '#d5e5fa' : ''
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="16"
                          height="16"
                          style={{
                            marginRight: '3px',
                            marginBottom: '3px',
                            marginLeft: '3px'
                          }}
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M12 11V8l4 4-4 4v-3H8v-2h4zm0-9c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 18c4.42 0 8-3.58 8-8s-3.58-8-8-8-8 3.58-8 8 3.58 8 8 8z"
                            fill="rgba(44,123,229,1)"
                          />
                        </svg>
                        <span
                          style={
                            object.dereferencement_date ? { color: 'red' } : {}
                          }
                        >
                          {object.partner?.name}
                        </span>
                        {object.specific_label && ` - ${object.specific_label}`}
                        {computeEtatContrat(object) !== '' && (
                          <SoftBadge
                            bg={
                              computeEtatContrat(object) === 'Expiré'
                                ? 'warning'
                                : 'danger'
                            }
                            className="ms-1"
                          >
                            {computeEtatContrat(object)}
                          </SoftBadge>
                        )}
                        {computeInvoiceToProcess(object) > 0 && (
                          <div className="d-inline">
                            <SoftBadge bg="success" className="ms-1">
                              {computeInvoiceToProcess(object)} fact. à déposer
                            </SoftBadge>
                          </div>
                        )}
                        {computeInvoiceToPay(object) > 0 && (
                          <div className="d-inline">
                            <SoftBadge bg="success" className="ms-1">
                              {computeInvoiceToPay(object)} fact. en attente
                              paiem.
                            </SoftBadge>
                          </div>
                        )}
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={8}>
          <div className="">
            <Card className="mt-3">
              <Card.Body className="">
                <Row>
                  <Col xs={12} className="mt-3">
                    <h5 className="admin-title companyRightManagePeople mb-3">
                      Données{' '}
                      {selectedObject?.id && (
                        <>
                          du partenaire {selectedObject?.partner?.name}
                          {selectedObject?.specific_label &&
                            ` - ${selectedObject?.specific_label}`}
                        </>
                      )}{' '}
                      :
                    </h5>
                    {!selectedObject.id && (
                      <IconAlert variant="info">
                        <p className="mb-0">
                          Veuillez choisir un partenaire dans la colonne de
                          gauche pour afficher ses données.
                        </p>
                      </IconAlert>
                    )}
                  </Col>
                  {selectedObject.id && (
                    <ManageContracts3
                      id={selectedObject?.id}
                      setParentTrigger={setTrigger}
                    />
                  )}
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mt-3">
          <EmailLogsContractsTable />
        </Col>
      </Row>
    </>
  );
};
export default IndexContracts3;
